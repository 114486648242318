import { css } from "@emotion/core";
import { mq } from "../../../cssInJs";
import { padding } from "polished";

export default {
  pageTiles: css(
    mq({
      fontFamily: "'Raleway', sans-serif",
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      margin: ["0", "2rem -10px"],
      overflow: "hidden",
    })
  ),
  pageTile__tile: css(
    mq({
      flex: "0 0 100%",
      maxWidth: "100%",
      padding: "10px",
      paddingBottom: ["10px", "3rem"],
      marginBottom: ["2rem", "0"],
      "&:nth-of-type(even)": {
        ".pageTile__bsyc__bg": mq({
          order: ["0", null, "2"],
        }),
        ".pageTile__bsyc__content__details": mq({
          order: "1",
        }),
        "& .pageTile__bsyc__container": mq({
          paddingRight: ["0", null, "8rem"],
        }),
        "& .pageTile__bsyc__bg": {
          display: "flex",
          justifyContent: "end",
        },
        " & .pageTile__bsyc__wrapper": {
          left: "unset",
          right: "0",
        },
      },
      "&:nth-of-type(odd)": {
        "& .pageTile__bsyc__container": mq({
          paddingLeft: ["0", null, "3rem"],
        }),
        "& .pageTile__bsyc__bg": {
          display: "flex",
          justifyContent: "start",
        },
      },
    })
  ),
  tile__content: css({
    "&:hover": {
      textDecoration: "none",
      "& .pageTile__bsyc__bg2": {
        transform: "scale(1.05)",
      },
      "& .pageTile__bsyc__btn": {
        color: "#fff",
        background: "#055363 !important",
        // background: "#13607B !important",
        ".c-button--gooey": {
          color: "#fff",
        },
        ".c-button--gooey .c-button__blobs div": {
          transform: "scale(1.4) translateY(0) translateZ(0)",
        },
      },
    },
  }),
  tile__flip__wrapper: css({
    zIndex: "1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    cursor: "pointer",
    position: "relative",
    flexWrap: "wrap",
  }),
  tile__bg: css(
    mq({
      height: ["230px", "0"],
      paddingTop: ["0", "35%", "30%"],
      flex: "1 1 300px",
      overflow: "hidden",
      position: "relative",
    })
  ),
  tile__bg_wrapper: css(
    mq({
      position: "absolute",
      top: "0",
      left: "0",
      // transform: "translateX(-50%)",
      width: ["100%", "93%"],
      height: "100%",
      overflow: "hidden",
    })
  ),
  tile__bg2: css({
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    transition: "transform .2s",
    transformOrigin: "center center",
  }),
  tile__details: css(
    mq({
      overflow: "hidden",
      flexGrow: "1",
      width: "100%",
      bottom: "0",
      flex: "1 1 300px",
      transition: "all .2s ease-in-out",
      color: "#13607B",
      position: "relative",
      paddingTop: ["1.3rem", null, "0"],
      "& .pageTile__bsyc__container": mq({
        position: ["static,", null, "absolute"],
        top: "50%",
        left: "50%",
        transform: ["none", null, "translate(-50%, -50%)"],
        width: ["100%", null, "100%"],
        "& .pageTile__bsyc__title": {
          fontSize: "2rem",
          lineHeight: "1",
          margin: "0",
          paddingTop: ".5rem",
          paddingBottom: ".5rem",
          color: "#13607B",
          textTransform: "capitalize",
          fontWeight: "400",
        },
        "& .pageTile__bsyc__blue__line": mq({
          width: "2rem",
          height: "2px",
          backgroundColor: "#13607B",
          margin: [".2rem 0 1rem", ".5rem 0 1.5rem"],
        }),
        "& .pageTile__bsyc__desc": mq({
          lineHeight: ["29px", "1.3"],
          fontSize: "1rem",
          // minHeight: "70px",
          color: "#4A4E57",
          marginBottom: [".7rem", "0"],
        }),
        "& .pageTile__bsyc__btn": mq({
          backgroundColor: "#04768D",
          color: "white",
          border: "1px solid #04768D",
          padding: ".6875rem 1.4375rem",
          borderRadius: "4px",
          display: "inline-block",
          fontSize: ["13px", "15px"],
          marginTop: ["0.8rem", "2rem"],
          position: "relative",
          overflow: "hidden",
          fontWeight: "700",
        }),
      }),
    })
  ),
};
