import React, { useLayoutEffect, useRef } from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
// import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/book-online', title: 'BOOKINGS', icon: 'book-online-icon-white.png', iconHover: 'book-online-icon-green.png' },
    { url: '/medical-services', title: 'MEDICAL', icon: 'services-icon-white.png', iconHover: 'services-icon-green.png' },
    { url: '/allied-health-services', title: 'ALLIED HEALTH', icon: 'our-team-icon-white.png', iconHover: 'our-team-icon-green.png' },
    { url: '/patient-information', title: 'PATIENT INFO', icon: 'patients-info-icon-white.png', iconHover: 'patients-info-icon-green.png' },
    { url: '/contact', title: 'CONTACT', icon: 'contact-us-icon-white.png', iconHover: 'contact-us-icon-green.png' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/*<div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div>*/}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/*<div className="bottomMenuPadding"></div>*/}
    </>
  );
}
