import { css  } from '@emotion/core'
import { mq } from '../../cssInJs'

export default {
  top: css(mq({
    //height: [44, null, 80],
    height: [44, null, 110],
    background: '#ffffff',
    position: 'fixed',
    width: '100%',
    zIndex: '999',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.3s'
  })),
  topScrolled: css(mq({
    height: [44, null, 75],
  })),
  navBox: css(
    mq({
      position: "fixed",
      right: "60px",
    })
  ),
  logo: css(mq({
  })),
  logoImg: css(mq({
    display: 'block',
    height: [30, null, 75],
  })),
  logoScrolled: css(mq({

  })),
  logoImgScolled: css(
    mq({
      transition: "all 0.3s",
      display: "block",
      height: [30, null, 57],
    })
  ),
  
}